<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false' :loading=loading1>
      <span slot='title'>
        <a-tag v-if='profile.merchantAuditStatus=="1"' color='yellow'>审核中</a-tag>
        <a-tag v-else-if='profile.merchantAuditStatus=="2"' color='red'>已拒绝</a-tag>
        <a-tag v-else-if='profile.merchantAuditStatus=="3"' color='blue'>通过</a-tag>
      </span>
      <span slot='extra'>
        <template>
          <a-space style="margin-left: 20px">
            <a v-if="profile.merchantAuditStatus == 2 || profile.merchantAuditStatus == 3" @click='handleDelete'>删除</a>
          </a-space>
        </template>
      </span>
      <a-descriptions title='商户信息'>
        <a-descriptions-item label='商户名称'>{{ profile.merchantName }}</a-descriptions-item>
        <a-descriptions-item label='营业执照号码'>{{ profile.businessLicenseNo }}</a-descriptions-item>
        <a-descriptions-item label='执照登记地址'>{{ profile.businessLicenseAddress }}</a-descriptions-item>
        <a-descriptions-item label='负责人'>{{profile.masterName}}</a-descriptions-item>
        <a-descriptions-item label='性别'>{{ profile.gender == "male" ? "男" : profile.gender == "female" ? "女" : "" }}</a-descriptions-item>
        <a-descriptions-item label='身份证号'>{{ profile.identifier }}</a-descriptions-item>
        <a-descriptions-item label='身份证地址'>{{ profile.idAddress }}</a-descriptions-item>
        <a-descriptions-item label='当前地址'>{{ profile.address }}</a-descriptions-item>
        <a-descriptions-item label='申请人手机号'>{{ profile.mobile }}</a-descriptions-item>
        <a-descriptions-item label='申请时间' :span="3">{{ profile.merchantApplyTime }}</a-descriptions-item>
        <a-descriptions-item label='身份证正面'>
          <img v-if='profile.idFront' :src='profile.idFront' width='100px' preview="0">
        </a-descriptions-item>
        <a-descriptions-item label='身份证反面'>
          <img v-if='profile.idBack' :src='profile.idBack' width='100px' preview="0">
        </a-descriptions-item>
        <a-descriptions-item label='负责人照片'>
          <img v-if='profile.masterImg' :src='profile.masterImg' width='100px' preview="0">
        </a-descriptions-item>
        <a-descriptions-item label='营业执照正面'>
          <img v-if='profile.businessLicenseImg' :src='profile.businessLicenseImg' width='100px' preview="0">
        </a-descriptions-item>
        <a-descriptions-item label='食品经营许可证' :span="2">
          <img v-if='profile.foodLicenseImg' :src='profile.foodLicenseImg' width='100px' preview="0">
        </a-descriptions-item>
        <template v-if="profile.merchantAuditStatus == 1">
          <a-descriptions-item label='' :span="3">
            <a-radio-group name="radioGroup" v-model="form.status">
              <a-radio :value="3">
                通过
              </a-radio>
              <a-radio :value="2">
                不通过
              </a-radio>
            </a-radio-group>
          </a-descriptions-item>
          <a-descriptions-item v-if="form.status == 2" label='不通过原因' :span="3">
            <a-input v-model="form.remark" />
          </a-descriptions-item>
          <a-descriptions-item :span="3">
            <a-button type='primary' @click='handleSubmit'>
              提交
            </a-button>
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getMember, updateApplyStatus, deleteApply } from '@/api/member'

export default {
  name: 'MemberApplyEdit',
  data() {
    return {
      loading1:[String,true],
      profile: {},
      form: {
        id: 0,
        status: 3,
        remark: ""
      }
    }
  },
  components: { STable, Ellipsis },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id)
  },
  activated() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id);
    // debugger;
  },
  methods: {
    handleInitial(id) {
      if (id) {
        this.form.id = id;
        this.loading1 = true;
        // let params = Object.assign( {id:"24011600003"});
        let params =this.$route.query;
        let member = getMember(params);
        member.then(result => {
          this.profile = Object.assign({}, this.profile, result)
          this.$previewRefresh()
          this.loading1=false;
        }).catch((err)=>{
          console.log(err);
        })
      }
    },
    handleSubmit() {
      updateApplyStatus(this.form).then(result => {
        this.$message.success('操作成功')
        this.$router.go(-1);
      })
    },
    handleDelete() {
      deleteApply(this.form).then(result => {
        this.$message.success('操作成功')
        this.$router.go(-1);
      })
    }
  }
}
</script>
